import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref } from "vue";
import VueRouterConfig from "@/router/VueRouterConfig";
export default {
  __name: 'Layout-Bar',
  setup(__props) {
    const active = ref(3);
    const change = () => {
      let options = {};
      switch (active.value) {
        case 'user':
          options = {
            name: 'User.UserList'
          };
          break;
        case 'key':
          options = {
            name: 'Key.KeyList',
            params: {
              UserName: 'self'
            }
          };
          break;
        case 'agent':
          options = {
            name: 'Agent.AgentList'
          };
          break;
        default:
          options = {
            name: 'Other.OtherIndex'
          };
      }
      VueRouterConfig.push(options);
    };
    return (_ctx, _cache) => {
      const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
      const _component_van_tabbar = _resolveComponent("van-tabbar");
      return _openBlock(), _createBlock(_component_van_tabbar, {
        modelValue: active.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => active.value = $event),
        "active-color": "#ee0a24",
        onClick: change
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tabbar_item, {
          icon: "user-circle-o",
          name: "user"
        }), _createVNode(_component_van_tabbar_item, {
          icon: "balance-o",
          name: "key"
        }), _createVNode(_component_van_tabbar_item, {
          icon: "friends-o",
          name: "agent"
        }), _createVNode(_component_van_tabbar_item, {
          icon: "setting-o",
          name: "other"
        })]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }
};