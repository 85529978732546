import AxiosLib from "@/apis/Axios/AxiosLib";
import ApisLang from "@/locales/Apis/ApisLang";

const apis = {
    ...AxiosLib,
    //登录
    Login(username,password){
        let fieldCodeLang = ApisLang('Common','Login');
        return AxiosLib.post('AgentApi/Api.Auth/login',{
            username,password,
        },
            true,true,
            fieldCodeLang,
        );
    },

    //获取首页信息
    getIndexData(){
        return AxiosLib.post('AgentApi/Api.index/getIndexData',{},
            true,true,
        );
    },

    //检查当前令牌是否可用
    checkToken(){
        return AxiosLib.post('AgentApi/Api.Auth/checkToken',{},
            false,false,
        );
    },
};
export default apis;