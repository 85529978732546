import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1a86d90e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "LayoutView"
};
const _hoisted_2 = {
  class: "LayoutRouter"
};
const _hoisted_3 = {
  class: "LayoutBar"
};
import LayoutBar from "@/layout/components/Layout-Bar";
import { onBeforeUnmount, onMounted } from "vue";
import Apis_Common from "@/apis/Apis_Common";
import VantMsgLib from "@/utils/Vant/VantMsgLib";
import CacheUserInfo from "@/utils/Cache/CacheUserInfo";
import Common_Router from "@/utils/Common/Common_Router";
export default {
  __name: 'Layout',
  setup(__props) {
    const checkLogin = () => {
      Apis_Common.checkToken().then(res => {
        let bool = Apis_Common.checkResCode(res);
        if (bool) {
          return;
        }
        VantMsgLib.notify(3, 'Token expired', 5000);
        VantMsgLib.alert('Token expired', 'Login exception');
        let userInfo = CacheUserInfo.getUserInfo();
        let ver = userInfo.version === 1 ? 'c' : 'y';
        CacheUserInfo.setUserInfo(null, null, null);
        Common_Router.push({
          path: '/' + ver
        });
      });
    };
    let handle = null;
    onMounted(() => {
      checkLogin();
      handle = setInterval(() => {
        setTimeout(() => {
          checkLogin();
        }, 0);
      }, 1000 * 30);
    });
    onBeforeUnmount(() => {
      clearInterval(handle);
      handle = null;
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view)]), _createElementVNode("div", _hoisted_3, [_createVNode(_unref(LayoutBar))])]);
    };
  }
};